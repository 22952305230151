.card {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.icon {
    font-size: 2.5rem;
    margin: 0.5rem;
}
.title {
    font-size: 1.5rem;
    margin: 0.25rem;
    font-weight: 700;
}
.description {
    margin: 0;
}