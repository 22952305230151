.header {
    display: flex;
    flex-wrap: nowrap;
    color: white;
    background-color: rgba(43,34,95,255);
}
.title {
    font-size: 1.75em;
    flex: 1;
    text-align: left;
}
.info {
    font-size: 1.5em;
    flex:1;
    text-align: center;
}
.leave {
    flex: 1;
    text-align: right;
}

@media only screen and (max-width: 800px) {
    .title {
        display: none;
    }
    .info {
        text-align: left;
    }
}