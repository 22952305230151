@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}

body {
    font-family: 'Gilroy', 'sans-serif';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.MuiButton-root {
    text-transform: none;
}
.loginFormBox{
    margin-top: 1%;
}
.googleLoginButton{
    margin-top: 2%;
}
.sideImage{
    flex: 1;
    background-size: cover;
    background-position: center;
}