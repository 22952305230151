@import '~antd/dist/antd.css';
@import '~@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';


/* Make tabs fill all space */
.ant-tabs-nav-list {
    width: 100%;
}
.ant-tabs-tab {
    flex-grow: 1;
    justify-content: center;
}