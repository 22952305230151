.wrapper {
    display: flex;
    flex-direction: row;
    height: 400px;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.container {
    min-height: 100px;
    flex: 0 0 33.3333%;
    padding: 0 0.5em;
}
.place {
    padding: 0.75em;
    height: 100%;
}
.first {
    background-color: #faad14;
}
.firstHeight {
    height: 100%;
}
.second {
    background-color: #bfbfbf;
}
.secondHeight {
    height: 75%;
}
.third {
    background-color: #ad5f31;
}
.thirdHeight {
    height: 50%;
}
.zeroHeight {
    height: 0;
}
.number {
    font-size: 2.5rem;
    font-weight: 700;
}
.support {
    font-size: 2rem;
    font-weight: 700;
}
.partyName {
    font-size: 1.5rem;
    font-weight: 700;
}
.mandates {

}

