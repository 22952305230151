.nominationsNumber {
    font-size: 2rem;
    font-weight: 700;
}
.nominationsName {
    font-size: 1.5rem;
    font-weight: 700;
}
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px
}
@media screen and (max-width: 1300px)  {
    .grid {
        display: block;
    }
}
