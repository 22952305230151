
.wrapper {
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 400px;
    height: 600px;
    z-index: 1000;
    box-shadow: -4px 4px 16px -4px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
}

.admin > * {
    color: green !important;
}

.chatInput {
    padding: 0.5em 0 !important;
}

.chatHeader {
    border-radius: 4px 4px 0 0;
    background-color: #282c34;
    text-align: center;
    padding: 1rem 0;
}
.chatTitle {
    color: white;
    font-size: 1.5rem;
}
.chatDesc {
    color: white;
}

.minimizedWrapper {
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.minimizedCircle {
    color: white;
    background-color: #282c34;
    cursor: pointer;
    box-shadow: -4px 4px 16px -4px rgba(0, 0, 0, 0.5);
}

.closeButton {
    color: white;
    position: absolute;
    font-size: 1rem;
    top: 0.5em;
    right: 0.5em;
}

.badge > :global(.ant-badge-dot) {
    width: 12px;
    height: 12px;
}