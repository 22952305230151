.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
    padding: 24px;
    background: #fff;
    text-align: center;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    float: left;
    text-align: center;
    font-size: 2.5em;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    vertical-align: middle;
}

.sider {
    background: #fff;
    margin: 50px 0 0 50px;
    padding: 10px;
    text-align: center;
}

@media only screen and (max-width: 1200px) {
    .sider {
        display: none;
    }
}

.account {
    float: right;
    color: white;
}
