.opinionBtn {
    margin: 8px;
    font-size: 1.5em;
    height: auto;
    width: 95%;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}
.stepper{
    font-family: 'Gilroy', 'sans-serif';
}
.questionDivider{
    background-color: #d9d9d9;
    width: 0.4%;
    height: 95%;
}
.opinionContainer {
    display: block;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
.container{
    align-items: center;
    width: 45%;
    margin: 0px 5%;
}
.questionContent{
    display:flex;
}
.sliderContainer {
    display: inline;
    align-items: center;
}
.sliderTextContainer {
    display: flex;
    font-size: 18px;
}
.slider {
    margin: 0 0px;
}
.sliderLabel {
    text-align: left;
    width: 70%;
    font-size: 18px;
    /*A bit longer than "Tiesioginis kontaktas" */
}
.sliderIndicator {
    /* About the width of 1000000 € */
    text-align: right;
    width: 30%
}

.navBtnContainer {
    padding-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.navBtn {
    margin: 8px;
}

.navBtn + .navBtn {
    margin-left: 20px;
}

@media only screen and (max-width: 810px) {
    .questionDivider{
        width: 95%;
        height: 0.4%;
        margin-bottom: 12px;
    }
    .questionContent{
        display:block;
    }
    .container{
        align-items: center;
        width: 90%;
        margin: 0px 5%;
        margin-bottom: 12px;
    }
}