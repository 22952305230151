body, html {
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}

.header {
  background-color: rgba(43,34,95,255);
  padding: 0 20px; /* Adjust padding as needed */
  height: 64px; /* Set a fixed height for the header */
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-and-text {
  display: flex;
  align-items: center;
}

.logo {
  margin-left: -10px;
  height: 60px; /* Set a fixed height for the logo */
  width: auto; /* Maintain aspect ratio */
}

.logo-text {
  color: white; /* Adjust text color */
  font-size: 2em; /* Adjust font size as needed */
  font-weight: 900;
  font-family: 'Gilroy', 'sans-serif';
}

.header-text {
  color: white;
  margin-right: 0px; /* Increase this value to move the text farther from the button */
  padding-left: 10px;
  padding-right: 10px;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align to the left */
}