
@font-face {
    font-family: 'Gilroy';
    src: url('../../fonts/Gilroy/Gilroy-Medium.woff') format('woff');
}
.listitem {
    font-family: 'Gilroy', 'sans-serif';
    text-align: left;
    align-items: center;
    margin: 10px 0;
    font-size: 18px;
    font-weight: 700;
}
.playertag{
    padding-left: 5px;
    color: rgba(0,0,0,0.4)
}