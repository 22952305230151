
.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.item {
    padding: 0.5rem;
    font-size: 1.5em;
    margin: 8px;
    border-radius: 4px;
}